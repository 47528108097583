import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08828c86"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-btns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isShare === false)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (
          [
            _ctx.TASK_STATUS_ENUM.WAIT_SCREEN,
            _ctx.TASK_STATUS_ENUM.WAIT_UPDATE,
          ].includes(_ctx.mainInfo.tag)
            && _ctx.SET_STYLE
        )
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "item",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleChangeStatus(_ctx.TASK_STATUS_ENUM.WAIT_CUSTOMER_SELECT)))
              }, "选为款式"))
            : _createCommentVNode("", true),
          (
          _ctx.mainInfo.tag === _ctx.TASK_STATUS_ENUM.WAIT_SUBMIT_YBF
            && _ctx.MADE_SAMPLE
        )
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "item",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleChangeStatus(_ctx.TASK_STATUS_ENUM.WAIT_CREATE_ORDER)))
              }, "订制样衣"))
            : _createCommentVNode("", true),
          (
          [
            _ctx.TASK_STATUS_ENUM.CREATED_ORDER,
            _ctx.TASK_STATUS_ENUM.WAIT_CREATE_ORDER,
          ].includes(_ctx.mainInfo.tag) === false
            && _ctx.SET_MAIN_COVER
            && !_ctx.mainInfo.stylePictureList?.[_ctx.coverIndex]?.primary
        )
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: "item",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleSetMainCover && _ctx.handleSetMainCover(...args)))
              }, "设为主图"))
            : _createCommentVNode("", true),
          (
          [
            _ctx.TASK_STATUS_ENUM.CREATED_ORDER,
            _ctx.TASK_STATUS_ENUM.WAIT_CREATE_ORDER,
          ].includes(_ctx.mainInfo.tag) === false
            && _ctx.UPLOAD_IMAGE
        )
            ? (_openBlock(), _createElementBlock("div", {
                key: 3,
                class: "item",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleUploadPicture && _ctx.handleUploadPicture(...args)))
              }, "上传图片"))
            : _createCommentVNode("", true),
          (
          _ctx.EDIT_IMAGE
            && [
              _ctx.TASK_STATUS_ENUM.WAIT_SCREEN,
              _ctx.TASK_STATUS_ENUM.WAIT_CUSTOMER_SELECT,
              _ctx.TASK_STATUS_ENUM.WAIT_UPDATE,
              _ctx.TASK_STATUS_ENUM.FAIL,
              _ctx.TASK_STATUS_ENUM.WAIT_SUBMIT_YBF,
            ].includes(_ctx.mainInfo.tag)
        )
            ? (_openBlock(), _createElementBlock("div", {
                key: 4,
                class: "item",
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleGoEditor && _ctx.handleGoEditor(...args)))
              }, "在线编辑"))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    (
        _ctx.CREATE_REMARK
          && [
            _ctx.TASK_STATUS_ENUM.CREATED_ORDER,
          ].includes(_ctx.mainInfo.tag) === false
      )
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "item",
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleAddMarker && _ctx.handleAddMarker(...args)))
        }, "添加批注"))
      : _createCommentVNode("", true),
    (_ctx.TOGGLE_REMARK)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "item",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.handleToggleMarker()))
        }, _toDisplayString(_ctx.visibleMarker ? '隐藏批注' : '显示批注'), 1))
      : _createCommentVNode("", true),
    (_ctx.DOWNLOAD_IMAGE)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: "item",
          onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.handleDownloadSource && _ctx.handleDownloadSource(...args)))
        }, "下载款式"))
      : _createCommentVNode("", true)
  ]))
}
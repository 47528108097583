
import { defineComponent, ref } from 'vue';
import { ElMessage } from 'element-plus';
import { uploadStylePicture } from '@/modules/task-manage/design-task/api';

export default defineComponent({
  emits: ['update:visible', 'success'],
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup(props, { emit }) {
    const formData = ref({
      urls: [],
    });
    const handleClose = () => {
      formData.value.urls = [];
      emit('update:visible', false);
    };
    const handleConfirm = async () => {
      if (formData.value.urls.length === 0) {
        ElMessage.error('至少上传一张图片');
        return;
      }
      await uploadStylePicture({
        designTaskDetailId: props.id,
        pictures: formData.value.urls,
      });
      emit('update:visible', false);
      emit('success');
    };
    return {
      handleConfirm,
      formData,
      handleClose,
    };
  },
});

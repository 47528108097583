import { ref, watch } from 'vue';
import { isEmpty } from 'lodash-es';
import { ElMessageBox } from 'element-plus';

import { useAccountStore } from '@/store/account';
import { genID } from '@/utils';

import { getMarkerList, updateMarker, replyMarker, deleteMarker } from '../../../api/editor';
import { IGetMarkerRes, IMarkerCommentRes, IUpdateMarkerRes } from '../../../api/types';
import { IMarkerItem, IMarkerCommentItem } from '../components/detail-modal/marker/type';

export default (pictureId: { value: string; }) => {
  const accountStore = useAccountStore();
  const isMarking = ref(false); // 新增标注
  const markerList = ref<IMarkerItem[]>([]); // 标注列表
  const visibleMarker = ref(true); // 标注显隐

  // 初始化标注节点信息
  const createMarkerData = (e: MouseEvent) => {
    const item: IMarkerItem = {
      id: `${markerList.value.length + 1}`,
      left: e.offsetX,
      top: e.offsetY,
      visible: true,
      commentList: [],
      isReplying: true,
      isCreating: true,
    };
    return item;
  };

  // 初始化评论节点
  const createCommentData = (v: string, id: string, date: string) => {
    const item: IMarkerCommentItem = {
      id: id || genID(),
      name: accountStore.account?.username || '',
      date: date || new Date().getTime(),
      content: v,
    };
    return item;
  };

  // 监听鼠标释放
  const handleImgMouseUp = (e: MouseEvent) => {
    if (isMarking.value) {
      setTimeout(() => {
        isMarking.value = false;
      }, 100);
      markerList.value.push(createMarkerData(e));
    } else {
      markerList.value.forEach((item) => {
        if (item.isCreating) return;
        item.visible = false;
      });
    }
  };

  // 请求批注数据
  const fetchMarkerList = async () => {
    try {
      const res = await getMarkerList({
        pictureId: pictureId.value,
      });
      if (!isEmpty(res)) {
        markerList.value = res.data.map((item: IGetMarkerRes, idx: number) => ({
          id: `${idx + 1}`,
          left: +item.positionX,
          top: +item.positionY,
          visible: false,
          commentList: item.annotations.map((aItem: IMarkerCommentRes) => ({
            id: aItem.id,
            name: aItem.creatorName,
            date: aItem.createdTime,
            content: aItem.content,
          })),
          isReplying: false,
          isCreating: false,
        }));
      }
    } catch (err) {
      console.log('请求批注数据', err);
    }
  };

  watch(() => pictureId.value, (newValue) => {
    if (newValue) {
      fetchMarkerList();
    }
  }, { immediate: true });

  // 取消创建/回复
  const handleCancelReply = () => {
    if (!markerList.value.length) return;
    const idx = markerList.value.findIndex(item => item.visible);
    if (idx < 0) return;
    if (markerList.value[idx].isCreating) {
      markerList.value.pop();
    } else {
      markerList.value[idx].isReplying = false;
    }
  };

  // 控制所有标注显隐
  const handleToggleMarker = (flag: boolean) => {
    visibleMarker.value = flag;
  };

  // 添加标注
  const handleAddMarker = (e: MouseEvent) => {
    e.stopPropagation();
    handleToggleMarker(true);
    isMarking.value = true;
    handleCancelReply();
    markerList.value.forEach((item) => {
      item.visible = false;
    });
  };

  // 请求更新批注
  const fetchUpdate = async (idx: number, v?: string, id?: string) => {
    const res = await updateMarker({
      id: id || '',
      designStylePictureId: pictureId.value,
      positionX: markerList.value[idx].left,
      positionY: markerList.value[idx].top,
      content: v || markerList.value[idx].commentList[0].content,
    });
    return res;
  };

  // 请求删除批注
  const fetchDelete = async (id: string) => {
    await deleteMarker({
      id,
    });
  };

  // 确认创建/回复
  const handleConfirmReply = async (idx: number, v: string) => {
    try {
      let res = { data: {} as IUpdateMarkerRes };
      if (markerList.value[idx].isCreating) {
        res = await fetchUpdate(idx, v);
        markerList.value[idx].isCreating = false;
      } else {
        res = await replyMarker({
          replyTo: markerList.value[idx].commentList[0].id,
          content: v,
        });
      }
      markerList.value[idx].isReplying = false;
      markerList.value[idx].commentList.push(createCommentData(v, res?.data.id || '', res?.data.createdTime || ''));
    } catch (err) {
      console.log('确认创建/回复', err);
    }
  };

  // 显示回复
  const handleReply = (idx: number) => {
    markerList.value[idx].isReplying = true;
  };

  // 确认编辑
  const handleConfirmEdit = async (idx: number, id: string, v: string) => {
    try {
      await fetchUpdate(idx, v, id);
      const cIdx = markerList.value[idx].commentList.findIndex(item => item.id === id);
      markerList.value[idx].commentList[cIdx].content = v;
    } catch (err) {
      console.log('确认编辑', err);
    }
  };

  // 拖拽赋值
  const handleSetMarker = (idx: number, obj: {left: number; top: number;}) => {
    markerList.value[idx].left = obj.left;
    markerList.value[idx].top = obj.top;
  };

  // 请求拖拽更新
  const handleCofirmDrag = async (idx: number) => {
    if (markerList.value[idx].isCreating) return;
    try {
      await updateMarker({
        id: markerList.value[idx].commentList[0].id,
        designStylePictureId: pictureId.value,
        positionX: markerList.value[idx].left,
        positionY: markerList.value[idx].top,
      });
    } catch (err) {
      console.log('请求拖拽更新', err);
    }
  };

  // 确认删除弹窗-设置显隐
  const setCurDeleteCompIdx = async (idx: number) => {
    try {
      await ElMessageBox.confirm(
        '该批注下的回复都会一并删除',
        '删除该批注',
        {
          confirmButtonText: '删除',
          type: 'warning',
        },
      );
      await fetchDelete(markerList.value[idx].commentList[0].id);
      markerList.value.splice(idx, 1);
    } catch (err) {
      console.log(err);
    }
  };

  // 删除内容/评论
  const handleDelete = async (idx: number, commentIdx: number) => {
    if (commentIdx > 0) {
      try {
        await fetchDelete(markerList.value[idx].commentList[commentIdx].id);
        markerList.value[idx].commentList.splice(commentIdx, 1);
      } catch (err) {
        console.log('请求删除回复', err);
      }
      return;
    }
    setCurDeleteCompIdx(idx);
  };

  // 选中
  const handleSelect = (idx: number) => {
    if (markerList.value[idx].isCreating) return;
    handleCancelReply();
    markerList.value.forEach((item, i) => {
      item.visible = idx === i ? !item.visible : false;
    });
  };

  // 处理点击问题
  const handleListenClick = (e: MouseEvent) => {
    if (!isMarking.value) return;
    if ((e?.target as HTMLElement).id !== 'imgMain') {
      isMarking.value = false;
    }
  };

  return {
    visibleMarker,
    isMarking,
    markerList,
    handleAddMarker,
    handleImgMouseUp,
    handleCancelReply,
    handleConfirmReply,
    handleReply,
    handleDelete,
    handleConfirmEdit,
    handleSetMarker,
    handleSelect,
    handleToggleMarker,
    handleListenClick,
    fetchMarkerList,
    handleCofirmDrag,
  };
};


import { defineComponent, ref, computed } from 'vue';
import copy from 'copy-to-clipboard';
import { ElMessage } from 'element-plus';
import {
  getTaskShareCode,
} from '@/modules/task-manage/design-task/api';
import {
  IGetTaskShareCodeRes,
} from '@/modules/task-manage/design-task/api/types';

export default defineComponent({
  emits: ['update:visible'],
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup(props, { emit }) {
    const formData = ref<IGetTaskShareCodeRes>({
      shareCodePassword: '',
      shareCode: '',
    });
    const getCode = async () => {
      const { id } = props;
      const { data } = await getTaskShareCode({
        id,
      });
      formData.value = data;
    };
    const path = computed(() => {
      return `${window.location.origin}/#/design-task/share-detail/${props.id}?secret=${formData.value.shareCode}`;
    });
    const handleCopy = () => {
      copy(`${path.value}\n密码：${formData.value.shareCodePassword}`);
      ElMessage.success('复制成功');
    };
    const handleClose = () => {
      emit('update:visible', false);
    };
    const handleOpen = () => {
      getCode();
    };
    return {
      path,
      formData,
      getCode,
      handleCopy,
      handleClose,
      handleOpen,
    };
  },
});


import { defineComponent, PropType } from 'vue';
import {
  TASK_STATUS_ENUM,
} from '@/modules/task-manage/design-task/constant';
import { IGetDesignTaskDetailStyleInfoRes } from '@/modules/task-manage/design-task/api/types';
import usePermissionConfig from '@/modules/task-manage/design-task/use-permission-config';
import { useRouter } from 'vue-router';
import {
  exportStylesToZip,
} from '@/modules/task-manage/design-task/api';
import { datefuns } from 'cx-utils';

export default defineComponent({
  emits: [
    'changeStatus',
    'uploadPicture',
    'toggleMarker',
    'setMainCover',
    'addMarker',
  ],
  props: {
    isShare: {
      type: Boolean,
      required: true,
    },
    mainInfo: {
      type: Object as PropType<IGetDesignTaskDetailStyleInfoRes>,
      required: true,
    },
    taskId: {
      type: String,
      required: true,
    },
    styleId: {
      type: String,
      required: true,
    },
    coverIndex: {
      type: Number,
      required: true,
    },
    visibleMarker: {
      type: Boolean,
      required: true,
    },
    taskName: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const $router = useRouter();
    const {
      TOGGLE_REMARK,
      SET_MAIN_COVER,
      UPLOAD_IMAGE,
      EDIT_IMAGE,
      CREATE_REMARK,
      DOWNLOAD_IMAGE,
      SET_STYLE,
      MADE_SAMPLE,
    } = usePermissionConfig();
    const handleChangeStatus = (type: TASK_STATUS_ENUM) => {
      emit('changeStatus', type);
    };
    const handleUploadPicture = () => {
      emit('uploadPicture');
    };
    // 跳转在线编辑器
    const handleGoEditor = () => {
      const { mainInfo, taskId, styleId, coverIndex } = props;
      $router.push({
        name: 'DesignTaskManageEdtior',
        params: {
          id: mainInfo.stylePictureList[coverIndex].designPictureId,
          tag: mainInfo.tag,
          styleId,
          taskId,
        },
      });
    };
    const handleAddMarker = (e: MouseEvent) => {
      emit('addMarker', e);
    };
    const handleToggleMarker = () => {
      const { visibleMarker } = props;
      emit('toggleMarker', !visibleMarker);
    };
    const handleDownloadSource = async () => {
      const { styleId, mainInfo, taskName } = props;
      await exportStylesToZip({
        designTaskDetailId: styleId,
        filename: `妙想-${taskName}-${datefuns.formatTime(new Date(), 'YYYY-MM-DD HH_mm_ss')}.zip`,
      });
    };
    const handleSetMainCover = () => {
      emit('setMainCover');
    };
    return {
      SET_STYLE,
      MADE_SAMPLE,
      handleSetMainCover,
      handleChangeStatus,
      TASK_STATUS_ENUM,
      TOGGLE_REMARK,
      SET_MAIN_COVER,
      UPLOAD_IMAGE,
      EDIT_IMAGE,
      CREATE_REMARK,
      DOWNLOAD_IMAGE,
      handleUploadPicture,
      handleGoEditor,
      handleAddMarker,
      handleToggleMarker,
      handleDownloadSource,
    };
  },
});

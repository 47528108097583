
import { defineComponent, PropType, ref } from 'vue';
import { ElForm, ElMessage } from 'element-plus';
import {
  createDesignTaskDetailStyle,
  getStyleCategoryOpitons,
  collectStyleForPlugin,
} from '@/modules/task-manage/design-task/api';
import {
  IGetDesignTaskOptionsRes,
  IGetStyleCategoryOpitonsRes,
} from '@/modules/task-manage/design-task/api/types';
import { IFormData } from './type.d';
import { useRouter } from 'vue-router';
import {
  SELECT_IMAGE_SOURCE_ENUM,
} from '@/modules/task-manage/design-task/constant';
import { cloneDeep } from 'lodash-es';

export default defineComponent({
  components: {
  },
  emits: ['update:visible', 'success'],
  props: {
    currentTaskId: {
      type: String,
    },
    type: {
      type: String as PropType<SELECT_IMAGE_SOURCE_ENUM>,
      default: SELECT_IMAGE_SOURCE_ENUM.LINK,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: '',
    },
    domainName: {
      type: String,
      default: '',
    },
    sourcePage: {
      type: String,
      default: '',
    },
    taskOptions: {
      type: Array as PropType<IGetDesignTaskOptionsRes[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const $router = useRouter();
    const refForm = ref<InstanceType<typeof ElForm> | null>(null);
    const baseFormData:IFormData = {
      designTaskId: '',
      pictureTitle: '',
      urls: [],
      categoryClass1: '',
    };
    const cateOptions = ref<IGetStyleCategoryOpitonsRes[]>([]);
    const formData = ref<IFormData>(cloneDeep(baseFormData));
    const handleClose = async (type?: string) => {
      if (props.type === SELECT_IMAGE_SOURCE_ENUM.PLUGIN_COLLECT) {
        if (type === 'success') {
          const row = props.taskOptions.find(v => v.id === formData.value.designTaskId);
          if (row) {
            ElMessage.success(`已添加到想法${row.designTaskName}`);
          }
        }
        setTimeout(() => {
          window.close();
        }, 500);
      } else {
        formData.value = cloneDeep(baseFormData);
        await refForm.value?.resetFields();
        emit('update:visible', false);
      }
    };
    const handleConfirm = async () => {
      try {
        await refForm.value?.validate();
        // 类型为采集
        if (props.type === SELECT_IMAGE_SOURCE_ENUM.PLUGIN_COLLECT) {
          await collectStyleForPlugin({
            sourcePage: props.sourcePage,
            domainName: props.domainName,
            designTaskId: formData.value.designTaskId,
            pictureTitle: formData.value.pictureTitle,
            categoryClass1: formData.value.categoryClass1,
            pictures: formData.value.urls.map(v => ({
              pictureName: v.name,
              path: v.url,
            })),
          });
        } else {
          await createDesignTaskDetailStyle({
            designTaskId: formData.value.designTaskId,
            pictureTitle: formData.value.pictureTitle,
            categoryClass1: formData.value.categoryClass1,
            pictures: formData.value.urls.map(v => ({
              pictureName: v.name,
              path: v.url,
            })),
          });
        }
        emit('success');
        handleClose('success');
      } catch (e) {
        console.log(e);
      }
    };
    const handleCreateDesignTask = () => {
      $router.push({
        name: 'DesignTaskManageList',
        params: {
          type: 'create',
        },
      });
    };
    const getCates = async () => {
      if (cateOptions.value.length) return;
      const { data } = await getStyleCategoryOpitons();
      cateOptions.value = data;
    };
    const handleOpen = () => {
      getCates();
      const {
        currentTaskId,
        taskOptions,
        url,
      } = props;
      if (currentTaskId && taskOptions.some(v => v.id === currentTaskId)) {
        formData.value.designTaskId = currentTaskId;
      }
      if (url && SELECT_IMAGE_SOURCE_ENUM.PLUGIN_COLLECT) {
        formData.value.urls.push({
          url,
          name: url,
        });
      }
    };
    return {
      handleOpen,
      cateOptions,
      handleCreateDesignTask,
      formData,
      handleClose,
      rules: {
        urls: [
          {
            required: true,
            message: '图片不能为空',
          },
        ],
        pictureTitle: [
          {
            required: true,
            message: '款式标题不能为空',
          },
        ],
        designTaskId: [
          {
            required: true,
            message: '想法不能为空',
          },
        ],
        categoryClass1: [
          {
            required: true,
            message: '品类不能为空',
          },
        ],
      },
      handleConfirm,
      refForm,
    };
  },
  render() {
    return (
      <el-dialog
        title={`
          ${this.type === SELECT_IMAGE_SOURCE_ENUM.PLUGIN_COLLECT ? '采集款式' : '上传款式'}
        `}
        modelValue={this.visible}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        center
        close-on-click-modal={false}
        close-on-press-escape={false}
        show-close={false}
        width="640px"
        v-slots={{
          footer: () => (
            <el-row type="flex" justify="end">
              <el-button size="small" onClick={this.handleClose}>取消</el-button>
              <el-button
                type="primary"
                size="small"
                onClick={this.handleConfirm}
              >确定</el-button>
            </el-row>
          ),
        }}
      >
        <el-form
          ref="refForm"
          rules={this.rules}
          model={this.formData}
          class="upload_design_container"
        >
          <div class="flex">
            <el-form-item prop="urls">
              <div class="upload">
                {this.formData.urls.length === 0 ? (
                  <>
                    <div class="empty">
                      <div class="iconfont icon_icon_tzsc icon"></div>
                      <div class="text">请点击或将图片拖拽到这里上传</div>
                    </div>
                    <upload
                      type="hidden"
                      v-model={this.formData.urls}
                      limit={9}
                    />
                  </>
                ) : (
                  <>
                    <div class="imgs">
                      <upload
                        v-model={this.formData.urls}
                        limit={9}
                      />
                    </div>
                  </>
                )}
              </div>
            </el-form-item>
            <div class="padding-left-20" style="flex:1;">
              <div class="title">添加到</div>
              <el-form-item
                prop="designTaskId"
              >
                <el-select
                  v-model={this.formData.designTaskId}
                  filterable
                  class="select-full"
                  placeholder="请选择想法"
                >
                  {this.taskOptions.map(v => (
                    <el-option
                      value={v.id}
                      label={v.designTaskName}
                      key={v.id}
                    />
                  ))}
                </el-select>
              </el-form-item>
              <div class="title">品类</div>
              <el-form-item
                prop="categoryClass1"
              >
                <el-select
                  filterable
                  class="select-full"
                  placeholder="请选择品类"
                  v-model={this.formData.categoryClass1}
                >
                  {this.cateOptions.map(v => (
                    <el-option
                      value={v.value}
                      label={v.name}
                      key={v.value}
                    />
                  ))}
                </el-select>
              </el-form-item>
              <div class="title">款式标题</div>
              <el-form-item
                prop="pictureTitle"
              >
                <el-input
                  v-model={[this.formData.pictureTitle, ['trim']]}
                  maxlength={20}
                  placeholder="请输入款式标题，20个字符以内"
                />
              </el-form-item>
              <div class="create_btn" onClick={this.handleCreateDesignTask}>
                <div class="iconfont icon_icon_cj icon"></div>
                <div class="text">创建想法</div>
              </div>
            </div>
          </div>
        </el-form>
      </el-dialog>
    );
  },
});

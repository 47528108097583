
import { defineComponent, ref, onMounted, computed } from 'vue';
import {
  IMainInfo,
  ITabsItem,
  IListItem,
} from './types';
import {
  MODE_ENUM,
  SOURCE_ENUM,
  FILTER_BTN_ENUM,
} from './constant';
import {
  RESULT_STATUS_ENUM,
  RESULT_STATUS_LIST,
  AUDIT_STATUS_ENUM,
  SELECT_IMAGE_SOURCE_ENUM,
  STYLE_STATUS_ENUM,
  STYLE_STATUS_LIST,
  TASK_STATUS_ENUM,
} from '@/modules/task-manage/design-task/constant';
import { useRoute, useRouter } from 'vue-router';
import { datefuns } from 'cx-utils';
import { ElMessage } from 'element-plus';
import {
  getDesignTaskDetailV2,
  getIdeaDetailTabCount,
  deleteDesignTaskDetailStyle,
  batchAuditStyle,
  batchCollectStyle,
  exportStylesToExcel,
  styleBatchSubmitToCustomer,
} from '@/modules/task-manage/design-task/api/index';
import Item from './components/Item/index.vue';
import useGoodsList from './composables/use-goods-list';
import SelectImgDialog from '@/components/select-img-dialog/index.vue';
import DetailModal from './components/detail-modal/index.vue';
import CheckSharePasswordModal from './components/check-share-password-modal/index.vue';
import UploadDesignDialog from './components/upload-design-dialog/index.vue';
import ShareModal from './components/share-modal/index.vue';
import ExportPdf from './components/export-pdf/index.vue';
import EditModal from '@/modules/task-manage/design-task/views/list/components/edit-dialog/index.vue';
import { useMenuStore } from '@/store/menu';
import { useCommon } from './composables/use-common';
import usePermissionConfig from '@/modules/task-manage/design-task/use-permission-config';
import { useAccountStore } from '@/store/account';
import { trackingClick } from '@/utils/tracking';

export default defineComponent({
  components: {
    ExportPdf,
    Item,
    SelectImgDialog,
    DetailModal,
    CheckSharePasswordModal,
    UploadDesignDialog,
    ShareModal,
    EditModal,
  },
  setup() {
    const $router = useRouter();
    const accountStore = useAccountStore();
    const permissionConfig = usePermissionConfig();
    const menuStore = useMenuStore();
    const $route = useRoute();
    const exportPdfEl = ref<InstanceType<typeof ExportPdf> | null>(null);
    const tabs = ref<ITabsItem[]>([]);
    const selectKeys = ref<string[]>([]);
    const {
      getCommonConfig,
      designTaskOptions,
      categoryOptions,
      collectorOptions,
      channelOptions,
    } = useCommon();
    // 列表查询
    const {
      styleList,
      getList,
      isEmpty,
      params,
      handleResetParams,
    } = useGoodsList();
    // 上传文件弹窗
    const uploadStyleDialogVisible = ref(false);
    const handleUploadStyleBtn = () => {
      uploadStyleDialogVisible.value = true;
    };
    // 模式：批量选择｜普通
    const mode = ref<MODE_ENUM>(MODE_ENUM.NORMAL);
    // 来源：分享｜正常
    const source = ref<SOURCE_ENUM>(SOURCE_ENUM.NORMAL);
    // 详情
    const mainInfo = ref({} as IMainInfo);
    // 分享弹窗显示
    const shareModalVisible = ref(false);
    // 校验分享链接弹窗显示
    const checkSharePasswordModalVisible = ref(false);
    // 改变分享链接弹窗显示状态
    const handleChangeShareModalVisible = (visible: boolean) => {
      shareModalVisible.value = visible;
    };
    // 款式详情弹窗配置
    const detailConfig = ref({
      visible: false,
      styleId: '',
    });
    // 点击款式
    const handleImageItem = (row: IListItem) => {
      detailConfig.value.styleId = row.designTaskDetailId;
      detailConfig.value.visible = true;
    };
    // 切换TAB
    const handleChangeTab = async (reset: boolean = false) => {
      try {
        if (reset) {
          handleResetParams();
        }
        // 清空选中 取消选择模式
        selectKeys.value = [];
        mode.value = MODE_ENUM.NORMAL;
        await getList();
        const { data } = await getIdeaDetailTabCount({
          id: $route.params.id as string,
        });
        let list = [...tabs.value];
        list = list.map(v => ({
          ...v,
          count: data.find(item => v.value === item.value)?.quantity || '0',
        }));
        tabs.value = list;
      } catch (error) {
        console.log(error);
      }
    };
    // 获取详情
    const getInfo = async () => {
      try {
        const { data } = await getDesignTaskDetailV2({
          id: $route.params.id as string,
        });
        mainInfo.value = {
          ...data,
        };
        await handleChangeTab();
      } catch (error) {
        console.log(error);
      }
    };
    // 初始化
    const init = async () => {
      getCommonConfig();
      // 处理携带过来的参数
      const tag = $route.query.tag as TASK_STATUS_ENUM;
      if (tag && Object.values(TASK_STATUS_ENUM).includes(tag)) {
        if (tag === TASK_STATUS_ENUM.WAIT_SCREEN) {
          params.value.tag = STYLE_STATUS_ENUM.MATERIAL;
        } else {
          params.value.tag = STYLE_STATUS_ENUM.STYLE;
          params.value.selectionImgResults = '';
        }
      }
      await getInfo();
      // 假如携带过来的款式id存在于当前的款式列表 则打开
      if ($route.query.styleId) {
        const row = styleList.value.find(v => v.designTaskDetailId === $route.query.styleId);
        if (row) {
          handleImageItem(row);
        }
      }
    };
    onMounted(() => {
      let array = STYLE_STATUS_LIST;
      // 如果来源是分享 需要过滤对应tab选项
      if ($route.name === 'DesignTaskDetailFromShare') {
        params.value.tag = STYLE_STATUS_ENUM.STYLE;
        array = array.filter(v => v.value === STYLE_STATUS_ENUM.STYLE);
        source.value = SOURCE_ENUM.SHARE;
      }
      tabs.value = array.map(v => ({
        ...v,
        count: '0',
      }));
      // params.value.tag = TASK_STATUS_ENUM.WAIT_CUSTOMER_SELECT;
      if (source.value === SOURCE_ENUM.NORMAL) {
        init();
      } else {
        menuStore.getMenus('share');
        checkSharePasswordModalVisible.value = true;
      }
    });
    // 编辑弹窗显示
    const editModalVisible = ref(false);
    const handleEdit = () => {
      editModalVisible.value = true;
    };
    // 选图弹窗配置
    const selectImgDialogConfig = ref({
      visible: false,
      urls: [] as string[],
      type: SELECT_IMAGE_SOURCE_ENUM.LINK,
    });
    // 快速选图
    const handleSelectImgItem = async (url: string, type: 'quick' | 'normal') => {
      const id = localStorage.getItem('quick_design_id');
      if (type === 'quick' && id) {
        await batchCollectStyle({
          id,
          styleIdList: [url],
        });
        const row = designTaskOptions.value.find(v => v.id === id);
        if (row) {
          ElMessage.success(`已添加到想法${row.designTaskName}`);
        }
        handleChangeTab();
        return;
      }
      selectImgDialogConfig.value.urls = [url];
      selectImgDialogConfig.value.visible = true;
      selectImgDialogConfig.value.type = SELECT_IMAGE_SOURCE_ENUM.LINK;
    };
    const handleCloseDetailModal = () => {
      detailConfig.value.visible = false;
      handleChangeTab();
    };
    // 切换模式
    const handleChangeMode = (type: MODE_ENUM) => {
      mode.value = type;
      selectKeys.value = [];
    };
    // 改变选择id
    const handleChangeSelectKeys = (key: string) => {
      const index = selectKeys.value.findIndex(v => v === key);
      if (index === -1) {
        selectKeys.value.push(key);
      } else {
        selectKeys.value.splice(index, 1);
      }
    };
    // 全选
    const handleSelectAll = () => {
      if (selectKeys.value.length === styleList.value.length) {
        selectKeys.value = [];
      } else {
        selectKeys.value = styleList.value.map(v => v.designTaskDetailId);
      }
    };
    // 校验分享密码成功
    const handleCheckSharePasswordSuccess = () => {
      checkSharePasswordModalVisible.value = false;
      init();
    };
    // 点击过滤按钮
    const handleFilterBtn = async (type: FILTER_BTN_ENUM) => {
      // 需要校验选中数量的类型
      const validTypes = [
        FILTER_BTN_ENUM.PASS,
        FILTER_BTN_ENUM.FAIL,
        FILTER_BTN_ENUM.UPDATE,
        FILTER_BTN_ENUM.DELETE,
        FILTER_BTN_ENUM.COLLECT,
        FILTER_BTN_ENUM.EXCEL,
        FILTER_BTN_ENUM.PDF,
        FILTER_BTN_ENUM.SET_MATERIAL,
      ];
      if (selectKeys.value.length === 0 && validTypes.includes(type)) {
        ElMessage.error('请选择图片');
        return;
      }
      switch (type) {
        case FILTER_BTN_ENUM.SET_MATERIAL: {
          await styleBatchSubmitToCustomer(selectKeys.value);
          handleChangeTab();
          ElMessage.success('操作成功');
          break;
        }
        case FILTER_BTN_ENUM.FAIL:
        case FILTER_BTN_ENUM.UPDATE:
        case FILTER_BTN_ENUM.PASS: {
          const auditMap = {
            [FILTER_BTN_ENUM.FAIL]: AUDIT_STATUS_ENUM.FAIL,
            [FILTER_BTN_ENUM.UPDATE]: AUDIT_STATUS_ENUM.WAIT_UPDATE,
            [FILTER_BTN_ENUM.PASS]: AUDIT_STATUS_ENUM.PASS,
          };
          const submitKeys:string[] = [];
          selectKeys.value.forEach((v) => {
            const row = styleList.value.find(it => it.designTaskDetailId === v);
            if (!row) return;
            if (
              type === FILTER_BTN_ENUM.FAIL
              && row.selectionImgResults === RESULT_STATUS_ENUM.FAIL) return;
            if (
              type === FILTER_BTN_ENUM.UPDATE
              && row.selectionImgResults === RESULT_STATUS_ENUM.WAIT_UPDATE) return;
            if (
              type === FILTER_BTN_ENUM.PASS
              && row.selectionImgResults === RESULT_STATUS_ENUM.PASS) return;
            submitKeys.push(v);
          });
          if (submitKeys.length) {
            await batchAuditStyle({
              styleIdList: submitKeys,
              auditStatus: auditMap[type],
            });
          }
          handleChangeTab();
          ElMessage.success('操作成功');
          break;
        }
        case FILTER_BTN_ENUM.DELETE:
          await deleteDesignTaskDetailStyle(selectKeys.value);
          ElMessage.success('操作成功');
          handleChangeTab();
          break;
        case FILTER_BTN_ENUM.COLLECT:
          selectImgDialogConfig.value.urls = selectKeys.value;
          selectImgDialogConfig.value.type = SELECT_IMAGE_SOURCE_ENUM.BATCH_COLLECT;
          selectImgDialogConfig.value.visible = true;
          break;
        case FILTER_BTN_ENUM.PDF: {
          if (selectKeys.value.length > 100) {
            ElMessage.warning('导出的图片数不能超过100张');
            return;
          }
          const imgs = styleList.value
            .filter(v => selectKeys.value.includes(v.designTaskDetailId)).map(v => v.pictureLink);
          exportPdfEl.value?.handleExportPDF(imgs);
          break;
        }
        case FILTER_BTN_ENUM.EXCEL:
          await exportStylesToExcel({
            ids: selectKeys.value,
            filename: `${mainInfo.value.designTaskName}-${datefuns.formatTime(new Date(), 'YYYY-MM-DD')}.xlsx`,
          });
          break;
        default:
          break;
      }
    };
    const tabsConfig = computed(() => {
      return tabs.value.map(v => ({
        label: `${v.label}(${v.count})`,
        value: v.value,
      }));
    });
    const handleUploadTaskSuccess = () => {
      getCommonConfig();
      handleChangeTab();
    };
    // 找相似
    const handleSearch = (url: string, channelId: string) => {
      trackingClick({
        eventName: '找相似',
        taskId: $route.params.id,
      });
      const newPage = $router.resolve({
        name: 'SearchImageList',
        query: {
          url: encodeURIComponent(url),
          channelId: `${channelId}` === '2' || `${channelId}` === '3' ? '1' : channelId,
        },
      });
      window.open(newPage.href);
    };
    // 跳转想法
    const handleRouteToList = () => {
      $router.push({
        name: 'DesignTaskManageList',
      });
    };
    return {
      STYLE_STATUS_LIST,
      STYLE_STATUS_ENUM,
      RESULT_STATUS_LIST: [
        {
          label: '全部',
          value: '',
        },
        ...RESULT_STATUS_LIST,
      ],
      accountStore,
      permissionConfig,
      FILTER_BTN_ENUM,
      handleUploadTaskSuccess,
      channelOptions,
      exportPdfEl,
      handleCloseDetailModal,
      getInfo,
      handleEdit,
      editModalVisible,
      handleUploadStyleBtn,
      uploadStyleDialogVisible,
      handleChangeTab,
      designTaskOptions,
      categoryOptions,
      collectorOptions,
      handleFilterBtn,
      handleCheckSharePasswordSuccess,
      checkSharePasswordModalVisible,
      source,
      SOURCE_ENUM,
      shareModalVisible,
      handleChangeShareModalVisible,
      params,
      detailConfig,
      handleSelectAll,
      handleChangeSelectKeys,
      selectKeys,
      handleChangeMode,
      MODE_ENUM,
      mode,
      selectImgDialogConfig,
      handleImageItem,
      styleList,
      getList,
      isEmpty,
      handleSelectImgItem,
      mainInfo,
      SELECT_IMAGE_SOURCE_ENUM,
      handleSearch,
      handleRouteToList,
      tabsConfig,
    };
  },
});

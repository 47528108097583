import { ref, computed } from 'vue';
import {
  getDesignTaskDetailStyles,
} from '@/modules/task-manage/design-task/api/index';
import { IListItem, IParams } from '../types';
import {
  RESULT_STATUS_ENUM,
  STYLE_STATUS_ENUM,
} from '@/modules/task-manage/design-task/constant';
import { useRoute } from 'vue-router';
/* 支持 列表查询 */

export default () => {
  const $route = useRoute();
  const params = ref<IParams>({
    tag: STYLE_STATUS_ENUM.MATERIAL,
    selectionImgResults: '',
    designTaskId: $route.params.id as string,
    collectorId: '',
    categoryClass1: '',
    channelId: '',
  });
  const styleList = ref<IListItem[]>([]);
  const getList = async () => {
    try {
      const { data } = await getDesignTaskDetailStyles({
        ...params.value,
        collectorId: params.value.collectorId ? [params.value.collectorId] : [],
        categoryClass1: params.value.categoryClass1 ? [params.value.categoryClass1] : [],
        channelId: params.value.channelId === '' ? [] : [params.value.channelId],
      });
      styleList.value = data || [];
    } catch (e) {
      console.log('获取首页列表 error', e);
    }
  };
  const listLoading = ref<boolean>(false);
  const isEmpty = computed(() => {
    return !styleList.value.length && !listLoading.value;
  });
  const handleResetParams = () => {
    params.value.collectorId = '';
    params.value.categoryClass1 = '';
    params.value.channelId = '';
    params.value.selectionImgResults = '';
  };
  return {
    handleResetParams,
    params,
    styleList,
    getList,
    isEmpty,
  };
};

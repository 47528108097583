import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61cfbeb6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  class: "main",
  ref: "pdfEl"
}
const _hoisted_3 = { class: "main_bottom" }
const _hoisted_4 = { class: "common_layout" }
const _hoisted_5 = { class: "common_layout_header" }
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "main_bottom_layout" }
const _hoisted_8 = { class: "push_image_layout" }
const _hoisted_9 = { class: "main_cover" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "num" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, "推图：共" + _toDisplayString(_ctx.images.length) + "款", 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "li",
                  key: index
                }, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("img", {
                      src: item,
                      class: "img"
                    }, null, 8, _hoisted_10),
                    _createElementVNode("div", _hoisted_11, _toDisplayString(index + 1), 1)
                  ])
                ]))
              }), 128))
            ])
          ])
        ])
      ])
    ], 512)
  ]))
}
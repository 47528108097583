
import { defineComponent, ref, watch } from 'vue';
import { ElMessage, ElForm } from 'element-plus';
import {
  createDesignTasks,
  updateDesignTasks,
  getDesignTaskDetailV2,
} from '@/modules/task-manage/design-task/api';
import {
  ICreateDesignTaskReq,
} from '@/modules/task-manage/design-task/api/types';
import {
  getCurrentOrgUsers,
} from '@/modules/setting-manage/user-manage/api';
import {
  IFormData,
  IUsersItem,
} from './types';
import {
  VISIBLE_LIST,
  VISIBLE_ENUM,
} from '@/modules/task-manage/design-task/constant';
import { scrollToError } from '@/core/utils';
import CustomerSelect from '@/modules/task-manage/design-task/components/customer-select';
import { cloneDeep } from 'lodash-es';
import { SYSTEM_ENUM } from '@/constant/global';
import { ORG_USER_STATUS_ENUM } from '@/modules/setting-manage/user-manage/constant';
import {
  IGetCurrentOrgUsersRes,
} from '@/modules/setting-manage/user-manage/api/type';
import { useAccountStore } from '@/store/account';

export default defineComponent({
  components: {
    CustomerSelect,
  },
  emits: ['update:visible', 'success'],
  props: {
    visible: {
      type: Boolean,
      defalut: false,
    },
    id: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup(props, { emit }) {
    const loadOption = ref(false);
    const accountStore = useAccountStore();
    const userOptions = ref<IUsersItem[]>([]);
    const refForm = ref<InstanceType<typeof ElForm> | null>(null);
    const baseData: IFormData = {
      designTaskName: '',
      designTaskCode: '',
      customerId: '',
      customerName: '',
      specialInstructions: '',
      visibleScope: VISIBLE_ENUM.SELF,
      visibleUserIds: [],
    };
    const formData = ref<IFormData>(cloneDeep(baseData));
    const handleClose = async () => {
      await refForm.value?.resetFields();
      emit('update:visible', false);
    };
    const handleOpen = async () => {
      if (props.id) {
        const { data } = await getDesignTaskDetailV2({
          id: props.id,
        });
        formData.value = {
          ...data,
          visibleUserIds: (data.visibleUserIds && data.visibleUserIds.split(',')) || [],
        };
      } else {
        formData.value = cloneDeep(baseData);
      }
    };
    const handleConfirm = async () => {
      try {
        await refForm.value?.validate();
        const data: ICreateDesignTaskReq = {
          ...formData.value,
          visibleUserIds: formData.value.visibleUserIds.join(','),
          customerId: accountStore.account!.userId,
          customerName: accountStore.account!.username,
        };
        if (formData.value.id) {
          await updateDesignTasks(data);
        } else {
          await createDesignTasks(data);
        }
        ElMessage.success('新增成功');
        emit('success');
        handleClose();
      } catch (e) {
        scrollToError(refForm.value?.$el);
        console.log(e);
      }
    };
    const getUsers = async () => {
      try {
        if (userOptions.value.length) return;
        loadOption.value = true;
        const { data } = await getCurrentOrgUsers({
          selectSystemCode: [
            SYSTEM_ENUM.MOKEN,
          ],
          status: ORG_USER_STATUS_ENUM.ENABLE,
          currentPage: 1,
          pageSize: 10,
        });
        const getOrgPath = (orgList: IGetCurrentOrgUsersRes['orgList'], orgPath: string[]) => {
          if (orgList.length) {
            const row = orgList[0];
            orgPath.push(row.orgName);
            getOrgPath(row.children, orgPath);
          }
        };
        const users = data.map((v) => {
          const orgPath:string[] = [];
          getOrgPath(v.orgList, orgPath);
          return {
            label: `${v.username}(${orgPath.join('-')})`,
            value: v.userId,
          };
        });
        userOptions.value = users;
      } catch (error) {
        console.log(error);
      }
      loadOption.value = false;
    };
    const handleChangeVisibleScope = (type: VISIBLE_ENUM) => {
      if (type !== VISIBLE_ENUM.PART) {
        formData.value.visibleUserIds = [];
      }
    };
    watch(() => props.visible, (val) => {
      if (val) {
        handleOpen();
        getUsers();
      }
    }, {
      immediate: true,
    });
    return {
      loadOption,
      VISIBLE_ENUM,
      handleChangeVisibleScope,
      userOptions,
      handleOpen,
      formData,
      handleClose,
      rules: {
        designTaskName: [
          {
            required: true,
            message: '任务名称不能为空',
          },
        ],
      },
      handleConfirm,
      refForm,
    };
  },
  render() {
    return (
      <el-dialog
        title={`${this.formData.id ? '编辑' : '添加'}想法`}
        modelValue={this.visible}
        onClose={this.handleClose}
        center
        close-on-click-modal={false}
        close-on-press-escape={false}
        width="600px"
        custom-class="el-dialog-inner-scroll custom_body padding-0"
        show-close={false}
        v-slots={{
          footer: () => (
            <el-row type="flex" justify="end">
              <el-button size="small" onClick={this.handleClose}>取消</el-button>
              <el-button
                type="primary"
                size="small"
                onClick={this.handleConfirm}
              >确认</el-button>
            </el-row>
          ),
        }}
      >
          <el-form
            model={this.formData}
            rules={this.rules}
            ref="refForm"
            label-width="125px"
          >
            <el-form-item
              class="item left"
              label="想法名称："
              prop="designTaskName"
            >
              <el-input
                v-model={[this.formData.designTaskName, ['trim']]}
                maxlength="15"
              />
            </el-form-item>
            <el-form-item label="特殊说明：" prop="specialInstructions">
              <el-input
                v-model={this.formData.specialInstructions}
                type="textarea"
                rows={4}
                placeholder="请输入特殊说明"
                maxlength="100"
              />
            </el-form-item>
            <el-form-item label="可见范围：" prop="visibleScope">
              <el-radio-group
                v-model={this.formData.visibleScope}
                onChange={this.handleChangeVisibleScope}
              >
                {VISIBLE_LIST.map(v => (
                  <el-radio label={v.value} key={v.value}>{v.label}</el-radio>
                ))}
              </el-radio-group>
            </el-form-item>
            {this.formData.visibleScope === VISIBLE_ENUM.PART && this.loadOption === false && (
              <el-form-item
                label="可见人员："
                prop="visibleUserIds"
              >
                <el-select
                  v-model={this.formData.visibleUserIds}
                  multiple
                  clearable
                  class="select-full"
                  filterable
                >
                  {this.userOptions.map(v => (
                    <el-option
                      label={v.label}
                      key={v.value}
                      value={v.value}
                    />
                  ))}
                </el-select>
              </el-form-item>
            )}
          </el-form>
      </el-dialog>
    );
  },
});


import { defineComponent, ref, nextTick } from 'vue';
import { showFullScreenLoading, hideFullScreenLoading } from '@/core/http/helper';
import { jsPDF as JsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { datefuns } from 'cx-utils';

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup(props) {
    const loading = ref(false);
    const images = ref<string[]>([]);
    const pdfEl = ref<null|HTMLElement>(null);
    const exportPDF = async () => {
      // 打印
      try {
        if (!loading.value) {
          showFullScreenLoading();
        }
        const dom = pdfEl.value!;
        // eslint-disable-next-line no-undef
        const imgs: NodeListOf<HTMLImageElement> = dom.querySelectorAll('.img');
        // 如果获取到的图片节点数量与图片总数不一致 则挂起100ms 然后重新查询
        if (imgs.length !== images.value.length) {
          if (!loading.value) {
            loading.value = true;
          }
          setTimeout(() => {
            exportPDF();
          }, 100);
          return;
        }
        // 判断是否加载完成
        let load = true;
        imgs.forEach((v) => {
          if (!v.complete) {
            load = false;
          }
        });
        // 如果存在未加载完的节点 则挂起100ms 然后重新查询
        if (!load) {
          if (!loading.value) {
            loading.value = true;
          }
          setTimeout(() => {
            exportPDF();
          }, 100);
          return;
        }
        window.scrollTo(0, 0);
        const canvas = await html2canvas(dom, {
          allowTaint: true,
          useCORS: true,
          width: dom.offsetWidth, // dom 原始宽度
          height: dom.offsetHeight,
          scale: 1,
        });
        // 内容初始宽度
        const contentWidth = canvas.width;
        // 内容初始高度
        const contentHeight = canvas.height;
        // 预设每页宽度
        const baseWidth = 592.28;
        // 预设每页高度
        const baseHeight = 841.89;
        const image = canvas.toDataURL('image/jpeg', 1);
        // 一页pdf显示html页面生成的canvas高度;
        const pageHeight = (contentWidth / baseWidth) * baseHeight;
        // 未生成pdf的html页面高度
        let leftHeight = contentHeight;
        // 页面偏移
        let position = 0;
        // 内容宽度
        const imgWidth = baseWidth;
        // 内容转换计算后总高度
        const imgHeight = (baseWidth / contentWidth) * contentHeight;
        let pdf:any;
        // 假如内容高度小于一页高度 使用兼容方案
        if (imgHeight < baseHeight) {
          pdf = new JsPDF({
            unit: 'pt',
            format: 'a4',
          });
          if (leftHeight < pageHeight) {
            pdf.addImage(image, 'JPEG', 0, 0, imgWidth, imgHeight);
          } else {
            while (leftHeight > 0) {
              pdf.addImage(image, 'JPEG', 0, position, imgWidth, imgHeight);
              leftHeight -= pageHeight;
              position -= 841.89;
              // 避免添加空白页
              if (leftHeight > 0) {
                pdf.addPage();
              }
            }
          }
        } else {
          pdf = new JsPDF({
            unit: 'px',
            format: [imgWidth, imgHeight],
            // format: 'a4',
          });
          pdf.addImage(image, 'JPEG', 0, 0, imgWidth, imgHeight);
        }
        pdf.save(`${props.name}-${datefuns.formatTime(new Date(), 'YYYY-MM-DD')}.pdf`);
      } catch (error) {
        console.log('导出pdf失败', error);
      }
      loading.value = false;
      hideFullScreenLoading();
      images.value = [];
    };
    const handleExportPDF = async (urls: string[]) => {
      images.value = urls;
      await nextTick();
      setTimeout(() => {
        exportPDF();
      }, 100);
    };
    return {
      images,
      pdfEl,
      handleExportPDF,
    };
  },
});

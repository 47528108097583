
import { defineComponent, PropType } from 'vue';
import {
  GOODS_STATE_ENUM,
  GOODS_STATE_LIST,
} from '@/modules/resource-manage/style/constant';
import { MODE_ENUM } from '../../constant';
import {
  IListItem,
} from '@/modules/task-manage/design-task/views/detail/types';
import {
  batchAuditStyle,
} from '@/modules/task-manage/design-task/api';
import {
  AUDIT_STATUS_ENUM,
  RESULT_STATUS_ENUM,
  STYLE_STATUS_ENUM,
} from '@/modules/task-manage/design-task/constant';
import { ElMessage } from 'element-plus';
import Copy from 'copy-to-clipboard';
import usePermissionConfig from '@/modules/task-manage/design-task/use-permission-config';

export default defineComponent({
  emits: ['select-img', 'image', 'change', 'reload', 'search'],
  props: {
    mode: {
      type: String as PropType<MODE_ENUM>,
      default: MODE_ENUM.NORMAL,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object as PropType<IListItem>,
      required: true,
    },
    tag: {
      type: String as PropType<STYLE_STATUS_ENUM>,
      required: true,
    },
    isShare: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      STYLE_DETAIL,
      UPLOAD_STYLE,
      COLLECT,
      SEARCH_IMAGE,
      PASS,
      FAIL,
      WAIT_UPDATE,
    } = usePermissionConfig();
    // 点击选图
    const handleSelectImgItem = (type: 'quick'|'normal' = 'normal') => {
      emit('select-img', props.data.designTaskDetailId, type);
    };
    // 点击图片
    const handleImageItem = () => {
      if (STYLE_DETAIL.value) {
        emit('image', props.data);
      } else {
        ElMessage.warning('无访问权限，请联系管理员');
      }
    };
    // 点击选中/取消
    const handleChangeItemCheck = () => {
      emit('change', props?.data?.designTaskDetailId);
    };
    // 点击改变状态
    const handleAuditItem = async (auditStatus: AUDIT_STATUS_ENUM) => {
      try {
        await batchAuditStyle({
          styleIdList: [props.data.designTaskDetailId],
          auditStatus,
        });
        ElMessage.success('操作成功');
        emit('reload');
      } catch (error) {
        console.log(error);
      }
    };
    const handleCopy = () => {
      Copy(props.data.designTaskDetailId);
      ElMessage.success('复制成功');
    };
    const handleSearch = () => {
      emit('search', props.data.pictureLink, props.data.channelId);
    };
    return {
      STYLE_DETAIL,
      UPLOAD_STYLE,
      COLLECT,
      SEARCH_IMAGE,
      PASS,
      FAIL,
      WAIT_UPDATE,
      handleCopy,
      RESULT_STATUS_ENUM,
      AUDIT_STATUS_ENUM,
      handleAuditItem,
      handleChangeItemCheck,
      MODE_ENUM,
      handleImageItem,
      GOODS_STATE_ENUM,
      GOODS_STATE_LIST,
      STYLE_STATUS_ENUM,
      handleSelectImgItem,
      handleSearch,
    };
  },
  components: {},
});

import { has, hasRoute } from '@/core/plugins/filter';
import { computed } from 'vue';

const usePermissionConfig = () => {
  return {
    /*
    * 添加任务
    * 控制：设计中心-任务-设计任务-添加任务
    * 跳转路由名称：
    * */
    CREATE: computed(() => has('MOKEN-SJZX-RW-SJRW-XZRW')),
    /*
    * 编辑任务
    * 控制：设计中心-任务-设计任务-编辑任务
    * 跳转路由名称：
    * */
    UPDATE: computed(() => has('MOKEN-SJZX-RW-SJRW-BJRW')),
    /*
    * 查看任务详情
    * 控制：设计中心-任务-设计任务-查看任务详情
    * 跳转路由名称：DesignTaskManageDetail
    * */
    DETAIL: computed(() => has('MOKEN-SJZX-RW-SJRW-CKRWXQ')),
    /*
    * 图片采集插件
    * 控制：设计中心-任务-设计任务-图片采集插件
    * 跳转路由名称：DesignTaskManageCollect
    * */
    COLLECT_PLUGIN: computed(() => has('MOKEN-SJZX-RW-SJRW-TPCJCJ')),
    /*
    * 采集
    * 控制：设计中心-任务-设计任务-任务详情-采集/批量采集/快速采集
    * 跳转路由名称：
    * */
    COLLECT: computed(() => has('MOKEN-SJZX-RW-SJRW-CJ')),
    /*
    * 查看款式详情
    * 控制：设计中心-任务-设计任务-任务详情-查看款式详情
    * 跳转路由名称：
    * */
    STYLE_DETAIL: computed(() => has('MOKEN-SJZX-RW-SJRW-CKKSXQ')),
    /*
    * 通过
    * 控制：设计中心-任务-设计任务-任务详情-通过/批量通过
    * 跳转路由名称：
    * */
    PASS: computed(() => has('MOKEN-SJZX-RW-SJRW-TG')),
    /*
    * 删除
    * 控制：设计中心-任务-设计任务-任务详情-删除/批量删除
    * 跳转路由名称：
    * */
    DELETE: computed(() => has('MOKEN-SJZX-RW-SJRW-SC')),
    /*
    * 导出
    * 控制：设计中心-任务-设计任务-任务详情-导出
    * 跳转路由名称：
    * */
    EXPORT: computed(() => has('MOKEN-SJZX-RW-SJRW-DC')),
    /*
    * 淘汰
    * 控制：设计中心-任务-设计任务-任务详情-淘汰/批量淘汰
    * 跳转路由名称：
    * */
    FAIL: computed(() => has('MOKEN-SJZX-RW-SJRW-TT')),
    /*
    * 待改
    * 控制：设计中心-任务-设计任务-任务详情-待改/批量待改
    * 跳转路由名称：
    * */
    WAIT_UPDATE: computed(() => has('MOKEN-SJZX-RW-SJRW-DG')),
    /*
    * 上传款式
    * 控制：设计中心-任务-设计任务-任务详情-上传款式
    * 跳转路由名称：
    * */
    UPLOAD_STYLE: computed(() => has('MOKEN-SJZX-RW-SJRW-SCKS')),
    // /*
    // * 提交客选
    // * 控制：设计中心-任务-设计任务-任务详情-提交客选
    // * 跳转路由名称：
    // * */
    // SUBMIT_CUSTOMER: computed(() => has('MOKEN-SJZX-RW-SJRW-TJKX')),
    /*
    * 设为主图
    * 控制：设计中心-任务-设计任务-任务详情-设为主图
    * 跳转路由名称：
    * */
    SET_MAIN_COVER: computed(() => has('MOKEN-SJZX-RW-SJRW-SWZT')),
    /*
    * 上传图片
    * 控制：设计中心-任务-设计任务-任务详情-上传图片
    * 跳转路由名称：
    * */
    UPLOAD_IMAGE: computed(() => has('MOKEN-SJZX-RW-SJRW-SCTP')),
    /*
    * 删除图片
    * 控制：设计中心-任务-设计任务-任务详情-删除图片
    * 跳转路由名称：
    * */
    DELETE_IMAGE: computed(() => has('MOKEN-SJZX-RW-SJRW-DELETETP')),
    /*
    * 下载图片
    * 控制：设计中心-任务-设计任务-任务详情-下载图片
    * 跳转路由名称：
    * */
    DOWNLOAD_IMAGE: computed(() => has('MOKEN-SJZX-RW-SJRW-XZTP')),
    /*
    * 分享
    * 控制：设计中心-任务-设计任务-任务详情-分享
    * 跳转路由名称：
    * */
    SHARE: computed(() => has('MOKEN-SJZX-RW-SJRW-FX')),
    // /*
    // * 提交版房
    // * 控制：设计中心-任务-设计任务-任务详情-提交版房
    // * 跳转路由名称：
    // * */
    // SUBMIT_YBF: computed(() => has('MOKEN-SJZX-RW-SJRW-TJBF')),
    /*
    * 在线编辑
    * 控制：设计中心-任务-设计任务-任务详情-在线编辑
    * 跳转路由名称：DesignTaskManageEdtior
    * */
    EDIT_IMAGE: computed(() => has('MOKEN-SJZX-RW-SJRW-ZXBJ')),
    /*
    * 添加/回复批注
    * 控制：设计中心-任务-设计任务-任务详情-添加/回复批注
    * 跳转路由名称：
    * */
    CREATE_REMARK: computed(() => has('MOKEN-SJZX-RW-SJRW-TJHFPZ')),
    /*
    * 编辑、删除批注
    * 控制：设计中心-任务-设计任务-任务详情-编辑、删除批注
    * 跳转路由名称：
    * */
    UPDATE_REMARK: computed(() => has('MOKEN-SJZX-RW-SJRW-BJSCPZ')),
    /*
    * 显示/隐藏批注
    * 控制：设计中心-任务-设计任务-任务详情-显示/隐藏批注
    * 跳转路由名称：
    * */
    TOGGLE_REMARK: computed(() => has('MOKEN-SJZX-RW-SJRW-XSYCPZ')),
    /*
    * 以图搜图
    * 控制：设计中心-以图搜图
    * 跳转路由名称：SearchImageList
    * */
    SEARCH_IMAGE: computed(() => hasRoute('SearchImageList')),
    /*
    * 选为款式
    * 控制：设计中心-任务-设计任务-任务详情-选为款式
    * 跳转路由名称：
    * */
    SET_STYLE: computed(() => has('MOKEN-SJZX-RW-SJRW-XWKS')),
    /*
    * 订制样衣
    * 控制：设计中心-任务-设计任务-任务详情-订制样衣
    * 跳转路由名称：
    * */
    MADE_SAMPLE: computed(() => has('MOKEN-SJZX-RW-SJRW-DZYY')),
  };
};

export default usePermissionConfig;

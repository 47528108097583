
import { defineComponent, PropType, ref } from 'vue';
import { ElMessage, ElForm } from 'element-plus';
import {
  getSupplierOptions,
  submitMadeSample,
} from '@/modules/task-manage/design-task/api';
import {
  IGetSupplierOptionsRes,
} from '@/modules/task-manage/design-task/api/types';
import {
  IFormData,
} from './types';

export default defineComponent({
  components: {
  },
  emits: ['update:visible', 'success'],
  props: {
    visible: {
      type: Boolean,
      defalut: false,
    },
    styleId: {
      type: String,
      required: true,
      default: '',
    },
    urls: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const supplierOptions = ref<IGetSupplierOptionsRes[]>([]);
    const refForm = ref<InstanceType<typeof ElForm> | null>(null);
    const formData = ref<IFormData>({
      sampleClothesName: '',
      designTaskDetailId: '',
      picturePaths: [],
      supplierId: '',
    });
    const handleClose = async () => {
      await refForm.value?.resetFields();
      emit('update:visible', false);
    };
    const getOptions = async () => {
      const { data } = await getSupplierOptions();
      supplierOptions.value = data;
    };
    const handleOpen = async () => {
      formData.value.picturePaths = props.urls.map(v => ({
        url: v,
      }));
      getOptions();
    };
    const handleConfirm = async () => {
      try {
        await refForm.value?.validate();
        await submitMadeSample({
          ...formData.value,
          picturePaths: formData.value.picturePaths.map(v => v.url),
          designTaskDetailId: props.styleId,
        });
        ElMessage.success('提交成功');
        emit('success');
        handleClose();
      } catch (e) {
        console.log(e);
      }
    };
    return {
      supplierOptions,
      handleOpen,
      formData,
      handleClose,
      rules: {
        sampleClothesName: [
          {
            required: true,
            message: '样衣名称不能为空',
          },
        ],
        supplierId: [
          {
            required: true,
            message: '供应商不能为空',
          },
        ],
        picturePaths: [
          {
            required: true,
            message: '款式图片不能为空',
          },
        ],
      },
      handleConfirm,
      refForm,
    };
  },
});

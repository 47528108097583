// 模式
export enum MODE_ENUM {
  /* 批量选择 */
  CHECKED = '1',
  /* 普通 */
  NORMAL = '2',
}

// 来源
export enum SOURCE_ENUM {
  /* 分享 */
  SHARE = '1',
  /* 普通 */
  NORMAL = '2',
}

// 过滤按钮类型
export enum FILTER_BTN_ENUM{
  /* 选为款式 */
  SET_MATERIAL = 'SET_MATERIAL',
  /* 通过 */
  PASS = 'PASS',
  /* 淘汰 */
  FAIL = 'FAIL',
  /* 待改 */
  UPDATE = 'UPDATE',
  /* 删除 */
  DELETE = 'DELETE',
  /* 采集 */
  COLLECT = 'COLLECT',
  /* 导出PDF */
  PDF = 'PDF',
  /* 导出EXCEL */
  EXCEL = 'EXCEL',
}


import { ElForm } from 'element-plus';
import { defineComponent, ref } from 'vue';
import {
  checkShareCode,
} from '@/modules/task-manage/design-task/api';
import { useRoute, useRouter } from 'vue-router';
import { useAccountStore } from '@/store/account';
import { USER_TYPE_ENUM } from '@/constant/system-info';

export default defineComponent({
  emits: ['success'],
  // props: {},
  setup(props, { emit }) {
    const accountStore = useAccountStore();
    const $route = useRoute();
    const $router = useRouter();
    const formEl = ref<null|InstanceType<typeof ElForm>>(null);
    const formData = ref({
      password: '',
    });
    const handleSubmit = async () => {
      try {
        const secret = $route.query.secret as string;
        await formEl.value?.validate();
        const { data } = await checkShareCode({
          shareCode: secret.split('密码')[0],
          shareCodePassword: formData.value.password,
        });
        // TODO:分享
        accountStore.setCurrentAccount({
          username: data.currentUserName,
          companyInfoList: [],
          userId: data.currentUserId,
          type: USER_TYPE_ENUM.FAKER,
        });
        emit('success');
      } catch (error: any) {
        if (
            error?.code === '500'
            && error?.message
            && ['超过有效分享时间', '设计任务不存在或已被删除'].includes(error.message)
        ) {
          $router.replace({
            name: 'DesignTaskFail',
          });
        }
        console.log(error);
      }
    };
    return {
      formEl,
      formData,
      handleSubmit,
      rules: {
        password: [{
          required: true,
          message: '请输入访问密码',
        }],
      },
    };
  },
});

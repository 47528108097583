
import { defineComponent, ref, PropType, Teleport, computed, onBeforeUnmount, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import CustomMarker from './marker/index.vue';
import UploadPictureModal from './upload-picture-modal/index.vue';
import ArrowLeft from '@/assets/images/design/icon_arrow_left.png';
import ArrowRight from '@/assets/images/design/icon_arrow_right.png';
import {
  getDesignTaskDetailStyleInfo,
  batchAuditStyle,
  deleteStylePicture,
  setStyleMainCover,
  styleSubmitToCustomer,
  exportStylesToZip,
} from '@/modules/task-manage/design-task/api';
import { IGetDesignTaskDetailStyleInfoRes } from '@/modules/task-manage/design-task/api/types';
import {
  TASK_STATUS_ENUM,
  AUDIT_STATUS_ENUM,
  TASK_STATUS_LIST,
} from '@/modules/task-manage/design-task/constant';
import { CHANNEL_ENUM } from '@/constant/global';
import useMarker from '../../composables/use-marker';
import { ElMessage, ElMessageBox } from 'element-plus';
import { IAttrItem } from './type.d';
import { datefuns } from 'cx-utils';
import usePermissionConfig from '@/modules/task-manage/design-task/use-permission-config';
import { trackingClick } from '@/utils/tracking';
import MadeSampleDialog from './made-sample-dialog/index.vue';
import HeaderBtns from './header-btns/index.vue';

export default defineComponent({
  components: {
    CustomMarker,
    Teleport: Teleport as any,
    UploadPictureModal,
    MadeSampleDialog,
    HeaderBtns,
  },
  emits: [
    'close',
  ],
  props: {
    styleId: {
      type: String,
      required: true,
    },
    isShare: {
      type: Boolean,
      required: true,
    },
    ids: {
      type: Array as PropType<string[]>,
      required: true,
      default: () => [],
    },
    taskId: {
      type: String,
      required: true,
    },
    taskName: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const permissionConfig = usePermissionConfig();
    const $router = useRouter();
    // 属性信息
    const attrs = ref<IAttrItem[]>([]);
    // 标注是否展示
    const markerVisible = ref(false);
    // 上传图片弹窗
    const uploadModalVisible = ref(false);
    const mainInfo = ref<IGetDesignTaskDetailStyleInfoRes>({
      designTaskDetailId: '',
      tag: TASK_STATUS_ENUM.WAIT_SCREEN,
      stylePictureList: [],
      operateLogs: [],
      skcDetail: {} as IGetDesignTaskDetailStyleInfoRes['skcDetail'],
    });

    const madeSampleDialogVisible = ref(false);
    // 当前封面图索引
    const currentCoverIndex = ref(0);
    // 当前款式id
    const currentId = ref('');
    // 当前图片ID
    const currentCoverId = computed(() => {
      return mainInfo?.value.stylePictureList[currentCoverIndex.value]?.designPictureId;
    });
    // 标注组件
    const {
      isMarking,
      markerList,
      visibleMarker,
      handleAddMarker,
      handleImgMouseUp,
      handleCancelReply,
      handleConfirmReply,
      handleReply,
      handleDelete,
      handleConfirmEdit,
      handleSetMarker,
      handleSelect,
      handleToggleMarker,
      handleListenClick,
      handleCofirmDrag,
    } = useMarker(currentCoverId);
    // 点击改变图片索引
    const handleChangeCoverIndex = (index: number) => {
      if (currentCoverIndex.value !== index) {
        currentCoverIndex.value = index;
        handleToggleMarker(true);
      }
    };
    const getDetailInfo = async (id: string, isReload: boolean = false) => {
      const { data } = await getDesignTaskDetailStyleInfo({
        designTaskDetailId: id,
      });
      mainInfo.value = data;
      if (!isReload) {
        handleChangeCoverIndex(data.stylePictureList.findIndex(v => v.primary));
      }
      const list:IAttrItem[] = [];
      try {
        if (data.skcDetail.productParameters) {
          const params: {[key:string]: string; } = JSON.parse(data.skcDetail.productParameters);
          Object.entries(params).forEach((v) => {
            const [label, content] = v;
            list.push({
              label,
              content,
            });
          });
        }
      } catch (error) {
        console.log('转译参数失败', error);
      }
      attrs.value = list;
    };
    // 当前款式索引
    const currentIndex = computed(() => {
      const { ids } = props;
      const index = ids.findIndex(v => v === currentId.value);
      return index;
    });
    // 点击删除图片
    const handleDeleteCover = async () => {
      const row = mainInfo.value.stylePictureList[currentCoverIndex.value];
      if (row) {
        if (row.primary) {
          ElMessage.error('主图不允许删除');
          return;
        }
        await deleteStylePicture({
          pictureId: row.designPictureId,
        });
        mainInfo.value.stylePictureList.splice(currentCoverIndex.value, 1);
        if (currentCoverIndex.value > mainInfo.value.stylePictureList.length - 1) {
          currentCoverIndex.value = 0;
        }
        ElMessage.success('删除成功');
      }
    };
    // 重新加载
    const reload = () => {
      getDetailInfo(currentId.value, true);
    };
    // 点击 上一款 下一款
    const handleChangeCurrentStyle = async (type:'next'|'prev') => {
      const { ids } = props;
      let index = currentIndex.value;
      if (type === 'next') {
        if (index + 1 > props.ids.length - 1) {
          reload();
          return;
        }
        index += 1;
      } else {
        if (index - 1 < 0) return;
        index -= 1;
      }
      const id = ids[index];
      currentId.value = id;
      await getDetailInfo(id);
    };
    const hanldeChangeMarkerVisible = (visible: boolean) => {
      markerVisible.value = visible;
    };
    // 点击下载图片
    const handleDownloadSource = async () => {
      await exportStylesToZip({
        designTaskDetailId: currentId.value,
        filename: `${mainInfo.value.skcDetail.skcName}-${datefuns.formatTime(new Date(), 'YYYY-MM-DD')}.zip`,
      });
    };
    // 设置主图
    const handleSetMainCover = async () => {
      const row = mainInfo.value.stylePictureList[currentCoverIndex.value];
      if (row) {
        if (row.primary) {
          return;
        }
        await setStyleMainCover({
          designTaskDetailId: mainInfo.value.designTaskDetailId,
          pictureId: row.designPictureId,
        });
        const list = [...mainInfo.value.stylePictureList];
        list.forEach((v, i) => {
          if (v.primary) {
            v.primary = false;
          }
          if (i === currentCoverIndex.value) {
            v.primary = true;
          }
        });
        mainInfo.value.stylePictureList = list;
        ElMessage.success('设置成功');
      }
    };
    // 改变状态
    const handleChangeStatus = async (type: TASK_STATUS_ENUM) => {
      switch (type) {
        case TASK_STATUS_ENUM.FAIL: {
          await ElMessageBox.confirm('请确认是否淘汰该款式', '提示');
          await batchAuditStyle({
            styleIdList: [currentId.value],
            auditStatus: AUDIT_STATUS_ENUM.FAIL,
          });
          break;
        }
        case TASK_STATUS_ENUM.WAIT_UPDATE: {
          await batchAuditStyle({
            styleIdList: [currentId.value],
            auditStatus: AUDIT_STATUS_ENUM.WAIT_UPDATE,
          });
          break;
        }
        case TASK_STATUS_ENUM.WAIT_SUBMIT_YBF: {
          await batchAuditStyle({
            styleIdList: [currentId.value],
            auditStatus: AUDIT_STATUS_ENUM.PASS,
          });
          break;
        }
        case TASK_STATUS_ENUM.WAIT_CUSTOMER_SELECT: {
          await ElMessageBox.confirm('请确认是否选为款式', '提示');
          await styleSubmitToCustomer({
            designTaskDetailId: mainInfo.value.designTaskDetailId,
          });
          break;
        }
        case TASK_STATUS_ENUM.WAIT_CREATE_ORDER: {
          madeSampleDialogVisible.value = true;
          return;
        }
        default:
          break;
      }
      ElMessage.success('操作成功');
      handleChangeCurrentStyle('next');
    };
    // 点击上传图片
    const handleUploadPicture = () => {
      uploadModalVisible.value = true;
    };
    const handleClose = () => {
      emit('close');
    };

    // 跳转在线编辑器
    const handleGoEditor = () => {
      $router.push({
        name: 'DesignTaskManageEdtior',
        params: {
          id: mainInfo.value.stylePictureList[currentCoverIndex.value].designPictureId,
          tag: mainInfo.value.tag,
          styleId: currentId.value,
          taskId: props.taskId,
        },
      });
    };
    // 点击店铺详情
    const handleShopInfo = () => {
      const newPage = $router.resolve({
        name: 'InformationManageShopManageDetail',
        query: {
          storeId: mainInfo.value.skcDetail.storeId,
          channelId: mainInfo.value.skcDetail.channelId,
        },
      });
      window.open(newPage.href);
    };
    // 点击商品详情
    const handleGoodsInfo = () => {
      let link = '';
      switch (mainInfo.value.skcDetail.channelId) {
        case CHANNEL_ENUM.HAIWAI:
        case CHANNEL_ENUM.MISSCIRCLE:
        case CHANNEL_ENUM.OHPOLLY:
          // 跨境商品库
          link = $router.resolve({
            name: 'ResourceManageStyleList',
            query: {
              skc: mainInfo.value.skcDetail.styleId,
              channelId: mainInfo.value.skcDetail.channelId,
            },
          }).href;
          break;
        case CHANNEL_ENUM.TAOPIN:
          // 淘品库
          link = $router.resolve({
            name: 'DomesticGoodsDetail',
            params: {
              spu: mainInfo.value.skcDetail.styleId,
            },
          }).href;
          break;
        case CHANNEL_ENUM.DOUPIN:
          // 抖品
          link = $router.resolve({
            name: 'DoupinkuDetail',
            query: {
              spu: mainInfo.value.skcDetail.styleId,
            },
          }).href;
          break;
        case CHANNEL_ENUM.INSTAGRAM:
          // ins目前无详情数据
          break;
        case CHANNEL_ENUM.VOGUE:
        case CHANNEL_ENUM.SINA_FASHION:
        case CHANNEL_ENUM.FASHION_NETWORK:
          // 品牌秀场目前无详情数据
          break;
        case CHANNEL_ENUM.JD:
          // 品牌京东详情
          link = $router.resolve({
            name: 'BrandDetailJingDong',
            query: {
              brandName: mainInfo.value.skcDetail.brandName,
              id: mainInfo.value.skcDetail.styleId,
            },
          }).href;
          break;
        case CHANNEL_ENUM.VIP_SHOP:
          // 品牌唯品会详情
          link = $router.resolve({
            name: 'BrandDetailWeiPin',
            query: {
              brandName: mainInfo.value.skcDetail.brandName,
              id: mainInfo.value.skcDetail.styleId,
            },
          }).href;
          break;
        default:
          break;
      }
      if (link) {
        window.open(link);
      }
    };
    const init = () => {
      currentId.value = props.styleId;
      getDetailInfo(currentId.value);
      document.body.style.overflow = 'hidden';
    };
    onMounted(() => {
      document.addEventListener('click', handleListenClick);
      init();
    });
    onBeforeUnmount(() => {
      document.body.style.overflow = '';
      document.removeEventListener('click', handleListenClick);
    });

    // 找相似
    const handleSearch = () => {
      trackingClick({
        eventName: '款式详情弹窗-找相似',
        taskId: props.taskId,
      });
      const newPage = $router.resolve({
        name: 'SearchImageList',
        query: {
          url: encodeURIComponent(mainInfo.value.stylePictureList?.[currentCoverIndex.value]?.pictureLink),
          channelId: `${mainInfo.value.skcDetail.channelId}` === '2' || `${mainInfo.value.skcDetail.channelId}` === '3'
            ? '1' : mainInfo.value.skcDetail.channelId,
        },
      });
      window.open(newPage.href);
    };

    return {
      currentId,
      madeSampleDialogVisible,
      handleShopInfo,
      permissionConfig,
      attrs,
      handleGoodsInfo,
      reload,
      handleSetMainCover,
      handleDeleteCover,
      handleChangeCoverIndex,
      handleClose,
      handleUploadPicture,
      uploadModalVisible,
      handleChangeStatus,
      handleDownloadSource,
      hanldeChangeMarkerVisible,
      markerVisible,
      currentCoverIndex,
      mainInfo,
      currentIndex,
      handleChangeCurrentStyle,
      isMarking,
      markerList,
      visibleMarker,
      handleAddMarker,
      handleImgMouseUp,
      handleCancelReply,
      handleConfirmReply,
      handleReply,
      handleDelete,
      handleConfirmEdit,
      handleSetMarker,
      handleSelect,
      handleToggleMarker,
      handleCofirmDrag,
      handleGoEditor,
      handleSearch,
    };
  },
  render() {
    return (
      <teleport to="body">
        <div class="container">
          <div class="scroller" onClick={this.handleClose}>
            <div class="container_top" onClick={e => e.stopPropagation()}>
              <header-btns
                onChangeStatus={this.handleChangeStatus}
                onUploadPicture={this.handleUploadPicture}
                onToggleMarker={this.handleToggleMarker}
                onAddMarker={this.handleAddMarker}
                onSetMainCover={this.handleSetMainCover}
                isShare={this.isShare}
                mainInfo={this.mainInfo}
                taskId={this.taskId}
                taskName={this.taskName}
                styleId={this.currentId}
                coverIndex={this.currentCoverIndex}
                visibleMarker={this.visibleMarker}
              />
              <div class="main">
                <div class="main_left">
                  <div class="cover_layout">
                    {this.mainInfo.tag !== TASK_STATUS_ENUM.WAIT_SCREEN && (
                      <div class="status">
                        {this.$filters.getEnumLabel(TASK_STATUS_LIST, this.mainInfo.tag)}
                      </div>
                    )}
                    <div class="cover_layout_top">
                      <div class="cover">
                        {this.mainInfo.stylePictureList?.[this.currentCoverIndex]?.pictureLink && (
                          <custom-image
                            hide-on-click-modal={true}
                            id="imgMain"
                            fit="contain"
                            class={`img ${this.isMarking ? 'pointer' : ''}`}
                            onMouseup={(e: any) => this.handleImgMouseUp(e)}
                            src={this.mainInfo.stylePictureList?.[this.currentCoverIndex]?.pictureLink}
                            preview-src-list={this.isMarking
                              ? null
                              : [this.mainInfo.stylePictureList?.[this.currentCoverIndex]?.pictureLink]
                            }
                          />
                        )}
                        <div class="main_operate">
                          {this.mainInfo.stylePictureList?.[this.currentCoverIndex]?.pictureLink
                            && this.permissionConfig.SEARCH_IMAGE.value
                            && <el-tooltip content="找相似" placement="bottom-end">
                            <div onClick={this.handleSearch} class="detail-model_search">
                              <i class="iconfont icon_zxs detail-model_search_icon" />
                            </div>
                          </el-tooltip>}
                        </div>
                        {this.mainInfo.stylePictureList?.[this.currentCoverIndex]?.primary && (
                          <div class="is_main_cover">
                            <div class="iconfont icon_icon_zt2 icon"></div>
                            <div class="txt">主图</div>
                          </div>
                        )}
                        {this.visibleMarker && this.markerList.map((item, idx) => {
                          return (
                            <custom-marker
                              key={item.id}
                              marker={item}
                              index={idx}
                              isShare={this.isShare}
                              onCancel={this.handleCancelReply}
                              onConfirm={this.handleConfirmReply}
                              onConfirmEdit={this.handleConfirmEdit}
                              onReply={this.handleReply}
                              onDelete={this.handleDelete}
                              onDrag={this.handleSetMarker}
                              onConfirmDrag={this.handleCofirmDrag}
                              onSelect={this.handleSelect}
                            />
                          );
                        })}
                        {[
                          TASK_STATUS_ENUM.CREATED_ORDER,
                          TASK_STATUS_ENUM.WAIT_CREATE_ORDER,
                        ].includes(this.mainInfo.tag) === false
                          && this.permissionConfig.DELETE_IMAGE.value
                          && this.isShare === false && (
                          <div
                            class="del el-icon-delete"
                            onClick={this.handleDeleteCover}
                          />
                        )}
                      </div>
                      <div class="thumbs">
                        <el-scrollbar>
                          <div class="thumbs_ul">
                            {this.mainInfo.stylePictureList.map((v, i) => (
                              <div
                                class={{
                                  item: true,
                                  active: i === this.currentCoverIndex,
                                }}
                                onClick={() => this.handleChangeCoverIndex(i)}
                              >
                                <custom-image
                                  class="img"
                                  fit="contain"
                                  src={v.pictureLink}
                                />
                                {v.primary && (
                                  <div class="is_main_tag">
                                    <div class="iconfont icon_icon_zt2 icon"></div>
                                    <div class="txt">主图</div>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </el-scrollbar>
                      </div>
                    </div>
                    <div class="cover_footer">
                      <div class="left">
                        {[
                          TASK_STATUS_ENUM.FAIL,
                          TASK_STATUS_ENUM.WAIT_SCREEN,
                        ].includes(this.mainInfo.tag) === false
                        && this.permissionConfig.FAIL.value
                        && (
                          <div
                            class={{
                              common_btn: true,
                              warn: true,
                            }}
                            onClick={() => this.handleChangeStatus(TASK_STATUS_ENUM.FAIL)}
                          >
                            <div class="iconfont icon icon_icon_tt"></div>
                            <div class="txt">淘汰</div>
                          </div>
                        )}
                      </div>
                      <div class="right">
                        {[
                          TASK_STATUS_ENUM.WAIT_UPDATE,
                          TASK_STATUS_ENUM.WAIT_SCREEN,
                        ].includes(this.mainInfo.tag) === false
                        && this.permissionConfig.WAIT_UPDATE.value
                        && (
                          <div
                            class={{
                              common_btn: true,
                              primary: true,
                            }}
                            onClick={() => this.handleChangeStatus(TASK_STATUS_ENUM.WAIT_UPDATE)}
                          >
                            <div class="iconfont icon icon_icon_dg"></div>
                            <div class="txt">待改</div>
                          </div>
                        )}
                        {[
                          TASK_STATUS_ENUM.WAIT_SUBMIT_YBF,
                          TASK_STATUS_ENUM.WAIT_CREATE_ORDER,
                          TASK_STATUS_ENUM.CREATED_ORDER,
                          TASK_STATUS_ENUM.WAIT_SCREEN,
                        ].includes(this.mainInfo.tag) === false
                        && this.permissionConfig.PASS.value
                        && (
                          <div
                            class={{
                              common_btn: true,
                              success: true,
                            }}
                            onClick={() => this.handleChangeStatus(TASK_STATUS_ENUM.WAIT_SUBMIT_YBF)}
                          >
                            <div class="iconfont icon icon_icon_tg"></div>
                            <div class="txt">通过</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {this.mainInfo.skcDetail.storeName && (
                    <div class="more_info_layout">
                      <div class="common_header">
                        <div class="txt">更多信息</div>
                        <div class="more">
                          {/**
                            this.mainInfo.skcDetail.storeId
                              && this.mainInfo.skcDetail.channelId === CHANNEL_ENUM.TAOPIN && (
                              <div
                                class="link"
                                onClick={this.handleShopInfo}
                                style={{ marginRight: '35px' }}
                              >
                                <div>查看店铺</div>
                              </div>
                            )
                          */}
                          <div class="link" onClick={this.handleGoodsInfo}>
                            <div>查看商品详情</div>
                            <div class="icon iconfont icon_icon_qck"></div>
                          </div>
                        </div>
                      </div>
                      <div class="name">{this.mainInfo.skcDetail.skcName}</div>
                      <div class="price">¥{this.mainInfo.skcDetail.discountPrice}</div>
                      <div class="store_name flex">
                        <div class="padding-right-5">{this.mainInfo.skcDetail.storeName}</div>
                        <div>
                          {this.mainInfo.skcDetail.shelfDate
                            ? `${this.$filters.formatTime(new Date(this.mainInfo.skcDetail.shelfDate), 'YYYY-MM-DD')}上架`
                            : ''}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div class="main_right">
                  <el-scrollbar>
                    {(this.mainInfo.skcDetail?.skcName || this.attrs?.length !== 0) && (
                      <div class="commmon_layout">
                        <div class="header">
                          <div class="txt">{this.mainInfo.skcDetail?.skcName}</div>
                        </div>
                        <div class="ul">
                          {this.attrs.map(v => (
                            <div class="li" key={v.label}>{v.label}：{v.content}</div>
                          ))}
                        </div>
                      </div>
                    )}
                    {/*
                    <div class="commmon_layout">
                      <div class="header">
                        <div class="txt">样衣信息</div>
                      </div>
                      <div class="ul">
                        <div class="li">设计款号：{this.mainInfo.sampleClothes?.prototypeCode || '未生成'}</div>
                        <div class="li">版单状态：{this.mainInfo.sampleClothes?.prototypeStatusName || '暂无'}</div>
                      </div>
                    </div>
                    */}
                    {!this.isShare && (
                      <div class="commmon_layout">
                        <div class="header">
                          <div class="txt">动态日志</div>
                        </div>
                        <div class="ul">
                          {this.mainInfo.operateLogs.map(v => (
                            <div class="li">
                              {v.creatorName} {this.$filters.formatTime(v.createdTime)} {v.operateContent}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </el-scrollbar>
                </div>
              </div>
              {this.currentIndex !== 0 && (
                <div class="prev page_btn">
                  <img
                    src={ArrowLeft}
                    class="icon"
                    onClick={() => this.handleChangeCurrentStyle('prev')}
                  />
                  <div class="txt">上一款</div>
                </div>
              )}
              {this.currentIndex !== this.ids.length - 1 && (
                <div class="next page_btn">
                  <img
                    src={ArrowRight}
                    class="icon"
                    onClick={() => this.handleChangeCurrentStyle('next')}
                  />
                  <div class="txt">下一款</div>
                </div>
              )}
              <div
                class="close_btn icon_icon_close_xl iconfont"
                onClick={this.handleClose}
              />
            </div>
          </div>
        </div>
        <upload-picture-modal
          v-model={[this.uploadModalVisible, 'visible']}
          id={this.mainInfo.designTaskDetailId}
          onSuccess={this.reload}
        />
        <made-sample-dialog
          v-model={[this.madeSampleDialogVisible, 'visible']}
          urls={this.mainInfo.stylePictureList.map(v => v.pictureLink)}
          onSuccess={this.reload}
          styleId={this.styleId}
        />
      </teleport>
    );
  },
});

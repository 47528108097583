
import { defineComponent, ref, reactive, PropType, onMounted } from 'vue';
import { IMarkerItem, IMarkerCommentItem } from './type';
import usePermissionConfig from '@/modules/task-manage/design-task/use-permission-config';

export default defineComponent({
  props: {
    marker: {
      type: Object as PropType<IMarkerItem>,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: -1,
    },
    isShare: {
      type: Boolean,
      required: true,
    },
  },

  emits: [
    'cancel',
    'confirm',
    'confirmEdit',
    'reply',
    'delete',
    'drag',
    'confirmDrag',
    'select',
  ],

  setup(props, { emit }) {
    const valueRelay = ref(''); // 编辑框值-底部
    const valueComment = ref(''); // 编辑框值-评论
    const commentId = ref(''); // 评论编辑ID
    const dragStart = reactive({
      x: 0,
      y: 0,
      left: 0,
      top: 0,
    }); // 拖拽开始位置信息
    const domImg = reactive({
      width: 0,
      height: 0,
    }); // 容器大小
    let isDraging = false; // 鼠标移动
    let isDowning = false; // 鼠标按下

    onMounted(() => {
      // 记录容器信息 40为节点信息
      const domImgRect = document.getElementById('imgMain')?.getBoundingClientRect();
      if (domImgRect) {
        domImg.width = domImgRect?.width - 40;
        domImg.height = domImgRect?.height - 40;
      }
    });

    // 输入-回复
    const handleInputReply = (v: string) => {
      valueRelay.value = v.trim().substr(0, 50);
    };

    // 编辑-评论
    const handleInputComment = (v: string) => {
      valueComment.value = v.trim().substr(0, 50);
    };

    // 编辑-评论-显示
    const handleEditComment = (item: IMarkerCommentItem) => {
      commentId.value = item.id;
      valueComment.value = item.content;
    };

    // 取消创建/回复
    const handleCancel = () => {
      emit('cancel');
    };

    // 确认创建/回复
    const handleConfirm = () => {
      emit('confirm', props.index, valueRelay.value);
      valueRelay.value = '';
    };

    // 取消编辑
    const handleCancelEdit = () => {
      commentId.value = '';
      valueComment.value = '';
    };

    // 确认编辑
    const handleConfirmEdit = (id: string) => {
      emit('confirmEdit', props.index, id, valueComment.value);
      handleCancelEdit();
    };

    // 回复
    const handleReply = () => {
      emit('reply', props.index);
    };

    // 删除内容/评论
    const handleDelete = (idx: number) => {
      emit('delete', props.index, idx);
    };

    // 拖拽开始
    const handleMouseDown = (e: MouseEvent) => {
      e.stopPropagation();
      if (e.buttons !== 1) return;
      isDowning = true;
      dragStart.x = +e.clientX;
      dragStart.y = +e.clientY;
      dragStart.left = +props.marker.left;
      dragStart.top = +props.marker.top;

      const onMove = (ev: MouseEvent) => {
        if (!isDowning) return;
        isDraging = true;
        let left = +ev.clientX - dragStart.x + dragStart.left;
        let top = +ev.clientY - dragStart.y + dragStart.top;
        left = left < 0 ? 0 : left;
        top = top < 0 ? 0 : top;
        emit('drag', props.index, {
          left: left > domImg.width ? domImg.width : left,
          top: top > domImg.height ? domImg.height : top,
        });
      };

      const onUp = () => {
        if (isDraging) {
          emit('confirmDrag', props.index);
        } else {
          emit('select', props.index);
        }
        isDraging = false;
        isDowning = false;
        document.removeEventListener('mousemove', onMove);
        document.removeEventListener('mouseup', onUp);
      };

      document.addEventListener('mousemove', onMove);
      document.addEventListener('mouseup', onUp);
    };

    const permissionConfig = usePermissionConfig();

    return {
      valueRelay,
      valueComment,
      commentId,
      handleInputReply,
      handleInputComment,
      handleCancel,
      handleConfirm,
      handleReply,
      handleDelete,
      handleEditComment,
      handleCancelEdit,
      handleConfirmEdit,
      handleMouseDown,
      permissionConfig,
    };
  },

  render() {
    return (
      <div class="marker" data-id="marker" style={{ left: `${this.marker.left}px`, top: `${this.marker.top}px` }}>
        <div
          class="marker_count"
          onMousedown={e => this.handleMouseDown(e)}
        >
          {this.marker.id}
        </div>
        {this.marker.visible && (
        <>
          <div class="dialog_container">
            {!this.marker.isCreating && <div class="dialog_container_header">批注</div>}
            <div class="dialog_container_wrap">
              {!this.marker.isCreating && <div class="dialog_container_content">
                {this.marker.commentList.map((item, idx) => {
                  return (
                    <div key={item.id} class="item">
                      <div class="top">
                        <div class="avatar">{item.name.substr(0, 1)}</div>
                        <div class="name">{item.name}</div>
                        <div class="date">{this.$filters.formatTime(item.date)}</div>
                        {(((!this.isShare && item.name !== '访客') || (this.isShare && item.name === '访客'))
                            && this.permissionConfig.UPDATE_REMARK.value)
                        && <div class="more">
                          <el-dropdown
                            v-slots={{
                              dropdown: () => (
                                <el-dropdown-menu class="more_menu">
                                  <el-dropdown-item
                                    icon="icon_icon_xgmc iconfont"
                                    onClick={() => this.handleEditComment(item)}
                                  >{idx === 0 ? '编辑批注' : '编辑回复'}</el-dropdown-item>
                                  <el-dropdown-item
                                    icon="icon_icon_sc1 iconfont"
                                    onClick={() => this.handleDelete(idx)}
                                  >删除</el-dropdown-item>
                                </el-dropdown-menu>
                              ),
                            }}
                          >
                            <div class="icon iconfont icon_icon_gd"></div>
                          </el-dropdown>
                        </div>}
                      </div>
                      <div class="content">
                        {item.id === this.commentId
                          ? <el-input
                          maxLength={50}
                          modelValue={this.valueComment}
                          type="textarea"
                          class="input"
                          placeholder={idx === 0 ? '批注内容' : '回复内容'}
                          onInput={this.handleInputComment}
                        /> : item.content}
                      </div>
                      {(item.id === this.commentId)
                      && <div class="dialog_container_footer edit">
                        <div
                          class="reply btn"
                          onClick={this.handleCancelEdit}
                        >取消</div>
                        <div
                          class="confirm btn"
                          onClick={() => this.handleConfirmEdit(item.id)}
                        >确认</div>
                      </div>}
                    </div>
                  );
                })}
              </div>}
              {this.marker.isReplying && <div class="dialog_container_comment">
                <el-input
                  maxLength={50}
                  modelValue={this.valueRelay}
                  type="textarea"
                  class="input"
                  placeholder={this.marker.isCreating ? '批注内容' : '请输入回复内容'}
                  onInput={this.handleInputReply}
                />
              </div>}
              {this.permissionConfig.CREATE_REMARK.value && <div class="dialog_container_footer">
                {this.marker.isReplying ? (
                  <>
                    <div
                      class="reply btn"
                      onClick={this.handleCancel}
                    >取消</div>
                    <div class="confirm btn" onClick={this.handleConfirm}>确认</div>
                  </>
                ) : (
                  <div class="reply btn" onClick={this.handleReply}>回复</div>
                )}
              </div>}
            </div>
          </div>
        </>)}
      </div>
    );
  },
});

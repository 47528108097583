import { ref } from 'vue';
import {
  IGetDesignTaskOptionsRes,
  IGetDesignTaskDetailStyleCategoryRes,
  IGetDesignTaskDetailStyleCollectorRes,
  IGetStyleChannelOptionsRes,
} from '@/modules/task-manage/design-task/api/types';
import {
  getDesignTaskOptions,
  getDesignTaskDetailStyleCategory,
  getDesignTaskDetailStyleCollector,
  getStyleChannelOptions,
} from '@/modules/task-manage/design-task/api/index';
import { useRoute } from 'vue-router';
/**
 * 获取所有下拉选项
 */
export const useCommon = () => {
  const $route = useRoute();
  const id = $route.params.id as string;
  const channelOptions = ref<IGetStyleChannelOptionsRes[]>([]);
  const designTaskOptions = ref<IGetDesignTaskOptionsRes[]>([]);
  const categoryOptions = ref<IGetDesignTaskDetailStyleCategoryRes[]>([]);
  const collectorOptions = ref<IGetDesignTaskDetailStyleCollectorRes[]>([]);
  // 来源渠道
  const getChannelOptions = async () => {
    const { data } = await getStyleChannelOptions({
      id,
    });
    channelOptions.value = data;
  };
  // 设计任务列表
  const getDesignOptions = async () => {
    try {
      const { data } = await getDesignTaskOptions();
      designTaskOptions.value = data;
    } catch (error) {
      console.log(error);
    }
  };
  // 分类
  const getCategoryOptions = async () => {
    try {
      const { data } = await getDesignTaskDetailStyleCategory({
        id,
      });
      categoryOptions.value = data;
    } catch (error) {
      console.log(error);
    }
  };
  // 采集人
  const getcollectorOptions = async () => {
    try {
      const { data } = await getDesignTaskDetailStyleCollector({
        id,
      });
      collectorOptions.value = data;
    } catch (error) {
      console.log(error);
    }
  };
  const getCommonConfig = () => {
    getChannelOptions();
    getDesignOptions();
    getcollectorOptions();
    getCategoryOptions();
  };
  return {
    getCommonConfig,
    designTaskOptions,
    categoryOptions,
    collectorOptions,
    channelOptions,
  };
};
